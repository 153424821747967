import { Box, Card, Typography, styled } from '@mui/material';

import { useQueueContext } from '@/context';
import { CoffeeCupIcon } from '@/icons';

import { DynamicCircularProgress } from './DynamicCircularProgress';

const Container = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  overflowY: 'scroll',
  width: '280px',

  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[400],
    borderRadius: '1rem',
  },
}));

// TODO i18n
export const Queue = () => {
  const { queue } = useQueueContext();

  return (
    <Container sx={{ p: 2, pt: 3 }}>
      {queue?.length ? (
        <>
          {queue.map((callRequest) => (
            <Card key={callRequest.id} sx={{ p: 2, mb: 2, bgcolor: 'grey.100' }}>
              <Typography sx={{ mb: 3 }} variant="h5">
                {callRequest?.terminalName}
              </Typography>
              <DynamicCircularProgress dueDate={callRequest.timeout} />
            </Card>
          ))}
        </>
      ) : (
        <Card sx={{ px: 2, py: 3, bgcolor: 'info.lighter' }}>
          <CoffeeCupIcon />
          <Typography sx={{ mt: 1, color: 'info.main' }} variant="body1">
            Aktuell keine Empfangsanfragen
          </Typography>
        </Card>
      )}
    </Container>
  );
};
