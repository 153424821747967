import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import { Box, SxProps, styled } from '@mui/material';
import { Track } from 'livekit-client';

import { aspectRatio } from '@/helpers';

const VideoWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
});

const Video = styled('video')({
  height: '100%',
});

const Overlay = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'grid',
});

const RatioFill = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
  backdropFilter: 'blur(5px)',
});

export interface VideoRendererProps {
  children?: ReactNode;
  hasRatioOverlay?: boolean;
  isLocal?: boolean;
  sx?: SxProps;
  track: Track;
}

export const VideoRenderer = ({
  children,
  hasRatioOverlay,
  isLocal,
  sx,
  track,
}: VideoRendererProps) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el || !track) {
      return;
    }
    handleHeight();
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);
    };
  }, [track, ref]);

  const handleHeight = () => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleHeight);
    return () => {
      window.removeEventListener('resize', handleHeight);
    };
  }, []);

  const isFrontFacing = track?.mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style: CSSProperties = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : 'none',
  };

  return (
    <VideoWrapper sx={{ ...sx }}>
      <Video ref={ref} style={style} />
      {hasRatioOverlay && (
        <Overlay sx={{ gridTemplateColumns: `auto ${height * aspectRatio}px auto` }}>
          <RatioFill />
          <div />
          <RatioFill />
        </Overlay>
      )}
      {children}
    </VideoWrapper>
  );
};
