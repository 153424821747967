import { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  circularProgressClasses,
  styled,
} from '@mui/material';

const Container = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const DynamicProgress = styled(CircularProgress)({
  position: 'absolute',
  [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' },
});

const TimeWrapper = styled(Box)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
});

export const DynamicCircularProgress = (props: CircularProgressProps & { dueDate: Date }) => {
  const { dueDate, ...circularProps } = props;
  const [value, setValue] = useState(Math.abs(new Date(dueDate).getTime() - Date.now()) / 1000);

  const sharedProps: CircularProgressProps = {
    size: 100,
    thickness: 4,
    variant: 'determinate',
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setValue(Math.abs(new Date(dueDate).getTime() - Date.now()) / 1000);
    }, 1000);
    return () => clearInterval(countdown);
  }, [dueDate]);

  return (
    <Container>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.grey[300],
        }}
        value={100}
        {...sharedProps}
      />
      <DynamicProgress {...circularProps} {...sharedProps} value={value} />
      <TimeWrapper>
        <Typography variant="h6">{`${Math.round(value)}s`}</Typography>
      </TimeWrapper>
    </Container>
  );
};
