import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CallRequest,
  useAcceptCallMutation,
  useGetQueueQuery,
  useQueueSubscription,
} from '@/graphql';

interface QueueContextProps {
  children: ReactNode;
}

interface QueueContextType {
  callId: string;
  callName: string;
  queue: CallRequest[];
  setQueue(queue: CallRequest[]): void;
  acceptCall(id: string): void;
  rejectCall(id: string): void;
  setCallId(callId: string): void;
  setCallName(callName: string): void;
}

const defaultContext: Partial<QueueContextType> = {
  callId: '',
  callName: '',
  queue: [],
};

export const QueueContext = createContext(defaultContext);

export const QueueContextProvider = ({ children }: QueueContextProps) => {
  const [callId, setCallId] = useState(defaultContext.callId);
  const [callName, setCallName] = useState(defaultContext.callName);
  const [queue, setQueueState] = useState(defaultContext.queue);

  const rejectedCallIds = useMemo(() => [], []);

  const setQueue = useCallback(
    (queue: CallRequest[]) => {
      if (!queue) {
        setQueueState([]);
      } else {
        const newQueue = queue?.filter((el) => !rejectedCallIds.includes(el.id));
        setQueueState(newQueue);
      }
    },
    [rejectedCallIds]
  );

  const { data } = useQueueSubscription();

  const [acceptCallMutation] = useAcceptCallMutation({
    onCompleted: (data) => setCallId(data.acceptCall),
  });

  const rejectCall = (id: string) => {
    rejectedCallIds.push(id);
    const newQueue = queue?.filter((el) => el.id !== id);
    setQueueState(newQueue);
  };

  const acceptCall = (callRequestId: string) => {
    acceptCallMutation({ variables: { id: callRequestId } });
  };

  useEffect(() => {
    setQueue(data?.queue);
  }, [data, setQueue]);

  return (
    <QueueContext.Provider
      value={{
        acceptCall,
        callId,
        callName,
        queue,
        setCallId,
        setCallName,
        rejectCall,
        setQueue,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};

export const useQueueContext = () => useContext(QueueContext);
