import { ReactNode } from 'react';

import { Card, styled } from '@mui/material';

const margin = 40;

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const StyledCard = styled(Card)({
  maxWidth: '1400px',
  margin: `${margin}px`,
  height: `calc(100vh - ${2 * margin}px)`,
  boxSizing: 'border-box',
  width: '100%',
});

interface PreJoinLayoutProps {
  children: ReactNode;
}

export const PreJoinLayout = ({ children }: PreJoinLayoutProps) => (
  <Container>
    <StyledCard
      sx={{
        p: 10,
      }}
    >
      {children}
    </StyledCard>
  </Container>
);
