import { ReactNode, useEffect, useState } from 'react';

import { Box, Theme, Typography, styled, useTheme } from '@mui/material';
import { Participant } from 'livekit-client';

import { stringsToList } from '@cubular/utils';
import { Fab } from '@minimals/core';

import { AvatarLogo } from '@/components';
import { useQueueContext } from '@/context';
import { CallAcceptIcon, CallDeclineIcon, FoamSVG } from '@/icons';

import ringtone from '../../../assets/incoming_call.mp3';
import { RemoteParticipantVideo } from './RemoteParticipantVideo';

const incomingCallBackground = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.darker,
  backgroundImage: `url(${FoamSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '300% 100%',
  backgroundPosition: 'center',
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.darker,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CtaArea = styled(Box)({
  width: '100%',
  height: '7rem',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center',
});

interface RemoteParticipantViewProps {
  children?: ReactNode;
  participant?: Participant;
}

// TODO i18n
export const RemoteParticipantView = ({ children, participant }: RemoteParticipantViewProps) => {
  const { acceptCall, rejectCall, setCallName, queue } = useQueueContext();
  const theme = useTheme();

  const [audio] = useState(new Audio(ringtone));
  audio.loop = true;

  const hasIncomingCall = !!queue?.length; /* && !callId */

  useEffect(() => {
    if (hasIncomingCall && !participant) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio, hasIncomingCall, participant]);

  const handleAccept = () => {
    setCallName(queue[0].terminalName);
    acceptCall(queue[0].id);
  };

  // TODO get callId from backend to handle this
  // if (!participant && callId) {
  //   return (
  //     <Container>
  //       <LoadingSpinner />
  //     </Container>
  //   );
  // }

  const ctaSx = { opacity: hasIncomingCall ? 1 : 0 };
  const avatarSx = hasIncomingCall ? { cursor: 'pointer' } : null;
  const containerSx = hasIncomingCall ? incomingCallBackground(theme) : '';
  const typoSx = { color: hasIncomingCall ? 'white' : 'info.light' };

  const headline = hasIncomingCall ? queue[0]?.terminalName : 'Warten auf greeets';
  const subline = hasIncomingCall
    ? stringsToList([queue[0]?.locationName, queue[0]?.locationInformation?.city]) || ''
    : 'Zur Zeit keine Check-Ins';

  if (!participant) {
    return (
      <Container sx={{ pt: 8, pb: 6, px: 6, ...containerSx }}>
        <Box>
          <Typography color="white" variant="h4">
            {headline}
          </Typography>
          <Typography sx={{ ...typoSx }} variant="h6">
            {subline}
          </Typography>
        </Box>
        <CtaArea>
          <Fab
            hoverColor={theme.palette.error.main}
            onClick={() => rejectCall(queue[0].id)}
            sx={ctaSx}
          >
            <CallDeclineIcon />
          </Fab>
          <AvatarLogo animate={hasIncomingCall} onClick={handleAccept} sx={avatarSx} />
          <Fab hoverColor={theme.palette.success.main} onClick={handleAccept} sx={ctaSx}>
            <CallAcceptIcon />
          </Fab>
        </CtaArea>
      </Container>
    );
  }

  return <RemoteParticipantVideo participant={participant}>{children}</RemoteParticipantVideo>;
};
