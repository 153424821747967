const PRIMARY = {
  lighter: '#D0F2FF',
  light: '#64B5F6',
  main: '#2196F3',
  dark: '#1976D2',
  darker: '#000632',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#64B5F6',
  main: '#2196F3',
  dark: '#1976D2',
  darker: '#0D3C61',
};

const COMMON = {
  primary: { ...PRIMARY },
  info: { ...INFO, contrastText: '#fff' },
};

export const palette = {
  light: {
    ...COMMON,
  },
  dark: {
    ...COMMON,
  },
};
