import { ReactNode } from 'react';

import { Box, Typography, styled } from '@mui/material';
import { Participant } from 'livekit-client';

import { AvatarLogo, VideoCover, VideoRenderer } from '@/components';
import { flexCenter } from '@/helpers';
import { useParticipant } from '@/hooks';
import { useMedia } from '@/zustand';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

interface LocalParticipantViewProps {
  children?: ReactNode;
  participant: Participant;
}

// TODO i18n
export const LocalParticipantView = ({ children, participant }: LocalParticipantViewProps) => {
  const videoEnabled = useMedia((state) => state.videoEnabled);
  const { cameraPublication } = useParticipant(participant);
  const isLoading = (!cameraPublication?.track || cameraPublication?.track.isMuted) && videoEnabled;

  return (
    <Container>
      <VideoRenderer hasRatioOverlay isLocal track={cameraPublication?.track} sx={{ flexGrow: 1 }}>
        <VideoCover
          isLoading={isLoading}
          sx={{ backgroundColor: 'primary.darker', pt: 8 }}
          videoEnabled={videoEnabled}
        >
          <Box
            sx={{
              ...flexCenter,
              backgroundColor: 'primary.darker',
              position: 'relative',
              height: '100%',
              width: '100%',
            }}
          >
            <Box sx={{ position: 'absolute', top: 0, textAlign: 'center' }}>
              <Typography color="white" variant="h4">
                Kamera deaktiviert
              </Typography>
              <Typography sx={{ color: 'info.light' }} variant="h6">
                Unten reaktivieren
              </Typography>
            </Box>
            <AvatarLogo />
          </Box>
        </VideoCover>
      </VideoRenderer>
      {children}
    </Container>
  );
};
