import { AudioTrack, Participant, Room } from 'livekit-client';

interface OnRoomConnectedProps {
  audioDevice: MediaDeviceInfo;
  audioEnabled: boolean;
  room: Room;
  videoDevice: MediaDeviceInfo;
  videoEnabled: boolean;
}

export const onRoomConnected = async ({
  audioDevice,
  audioEnabled,
  room,
  videoDevice,
  videoEnabled,
}: OnRoomConnectedProps) => {
  // make it easier to debug
  (window as any).currentRoom = room;

  if (audioEnabled) {
    if (audioDevice?.deviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDevice.deviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);
  }

  if (videoEnabled) {
    if (videoDevice?.deviceId && room.options.videoCaptureDefaults) {
      room.options.videoCaptureDefaults.deviceId = videoDevice.deviceId;
    }
    await room.localParticipant.setCameraEnabled(true);
  }
};

export const getAudioTracks = (participants: Participant[]): AudioTrack[] => {
  const tracks: AudioTrack[] = [];

  participants?.forEach((p) => {
    p.audioTracks.forEach((pub) => {
      if (pub.audioTrack) {
        tracks.push(pub.audioTrack);
      }
    });
  });
  return tracks;
};
