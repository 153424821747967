import { useCallback, useEffect, useState } from 'react';

import {
  LocalParticipant,
  Participant,
  ParticipantEvent,
  Track,
  TrackPublication,
} from 'livekit-client';

export interface ParticipantState {
  isSpeaking: boolean;
  isLocal: boolean;
  publications: TrackPublication[];
  cameraPublication?: TrackPublication;
  microphonePublication?: TrackPublication;
}

export const useParticipant = (participant: Participant): ParticipantState => {
  const [isAudioMuted, setAudioMuted] = useState(false);
  const [isVideoMuted, setVideoMuted] = useState(false);
  const [isSpeaking, setSpeaking] = useState(false);
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  const onPublicationsChanged = useCallback(() => {
    setPublications(Array.from(participant.tracks.values()));
  }, [participant]);

  useEffect(() => {
    const onMuted = (pub: TrackPublication) => {
      if (pub.kind === Track.Kind.Audio) {
        setAudioMuted(true);
      } else if (pub.kind === Track.Kind.Video) {
        setVideoMuted(true);
      }
    };

    const onUnmuted = (pub: TrackPublication) => {
      if (pub.kind === Track.Kind.Audio) {
        setAudioMuted(false);
      } else if (pub.kind === Track.Kind.Video) {
        setVideoMuted(false);
      }
    };

    const onIsSpeakingChanged = () => {
      setSpeaking(participant.isSpeaking);
    };

    // register listeners
    participant
      .on(ParticipantEvent.TrackMuted, onMuted)
      .on(ParticipantEvent.TrackUnmuted, onUnmuted)
      .on(ParticipantEvent.IsSpeakingChanged, onIsSpeakingChanged)
      .on(ParticipantEvent.TrackPublished, onPublicationsChanged)
      .on(ParticipantEvent.TrackUnpublished, onPublicationsChanged)
      .on(ParticipantEvent.TrackSubscribed, onPublicationsChanged)
      .on(ParticipantEvent.TrackUnsubscribed, onPublicationsChanged)
      .on(ParticipantEvent.LocalTrackPublished, onPublicationsChanged)
      .on(ParticipantEvent.LocalTrackUnpublished, onPublicationsChanged);

    // set initial state
    onIsSpeakingChanged();
    onPublicationsChanged();

    return () => {
      // cleanup
      participant
        .off(ParticipantEvent.TrackMuted, onMuted)
        .off(ParticipantEvent.TrackUnmuted, onUnmuted)
        .off(ParticipantEvent.IsSpeakingChanged, onIsSpeakingChanged)
        .off(ParticipantEvent.TrackPublished, onPublicationsChanged)
        .off(ParticipantEvent.TrackUnpublished, onPublicationsChanged)
        .off(ParticipantEvent.TrackSubscribed, onPublicationsChanged)
        .off(ParticipantEvent.TrackUnsubscribed, onPublicationsChanged)
        .off(ParticipantEvent.LocalTrackPublished, onPublicationsChanged)
        .off(ParticipantEvent.LocalTrackUnpublished, onPublicationsChanged);
    };
  }, [onPublicationsChanged, participant]);

  let muted: boolean | undefined;
  participant.audioTracks.forEach((pub) => {
    muted = pub.isMuted;
  });
  if (muted === undefined) {
    muted = true;
  }
  if (isAudioMuted !== muted) {
    setAudioMuted(muted);
  }

  return {
    isLocal: participant instanceof LocalParticipant,
    isSpeaking,
    publications,
    cameraPublication: participant.getTrack(Track.Source.Camera),
    microphonePublication: participant.getTrack(Track.Source.Microphone),
  };
};
