import { useTheme } from '@mui/material';

import { SvgComponentProps } from './svg-components.types';

export const MicIcon = ({ size = 24, fill }: SvgComponentProps) => {
  const { palette } = useTheme();
  const color = fill || palette.primary.main;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C14.2091 15 16 13.2091 16 11V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V11C8 13.2091 9.79086 15 12 15Z"
        fill={color}
      />
      <path
        d="M19 11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11C17 13.7614 14.7614 16 12 16C9.23858 16 7 13.7614 7 11C7 10.4477 6.55228 10 6 10C5.44772 10 5 10.4477 5 11C5.00407 14.4766 7.55907 17.4233 11 17.92V20H8.89C8.39847 20 8 20.3985 8 20.89V21.11C8 21.6015 8.39847 22 8.89 22H15.11C15.6015 22 16 21.6015 16 21.11V20.89C16 20.3985 15.6015 20 15.11 20H13V17.92C16.4409 17.4233 18.9959 14.4766 19 11V11Z"
        fill={color}
      />
    </svg>
  );
};

export const MutedMicIcon = ({ size = 24, fill }: SvgComponentProps) => {
  const { palette } = useTheme();
  const color = fill || palette.error.main;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.34229"
        y="3.5"
        width="30.2249"
        height="2.68451"
        rx="1.34226"
        transform="rotate(30 1.34229 0)"
        fill={color}
      />
      <path
        d="M12 15C14.2091 15 16 13.2091 16 11V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V11C8 13.2091 9.79086 15 12 15Z"
        fill={color}
      />
      <path
        d="M19 11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11C17 13.7614 14.7614 16 12 16C9.23858 16 7 13.7614 7 11C7 10.4477 6.55228 10 6 10C5.44772 10 5 10.4477 5 11C5.00407 14.4766 7.55907 17.4233 11 17.92V20H8.89C8.39847 20 8 20.3985 8 20.89V21.11C8 21.6015 8.39847 22 8.89 22H15.11C15.6015 22 16 21.6015 16 21.11V20.89C16 20.3985 15.6015 20 15.11 20H13V17.92C16.4409 17.4233 18.9959 14.4766 19 11V11Z"
        fill={color}
      />
    </svg>
  );
};
