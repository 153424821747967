import create from 'zustand';
import { combine, devtools, persist } from 'zustand/middleware';

export const useToken = create(
  devtools(
    persist(
      combine({ token: '' }, (set) => ({
        setToken: (token: string) => set(() => ({ token })),
      }))
    )
  )
);

export const useMedia = create(
  devtools(
    persist(
      combine({ audioEnabled: true, videoEnabled: true }, (set) => ({
        setAudioEnabled: (audioEnabled: boolean) => set(() => ({ audioEnabled })),
        setVideoEnabled: (videoEnabled: boolean) => set(() => ({ videoEnabled })),
      }))
    )
  )
);
