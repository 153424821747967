import { styled } from '@mui/material';
import { LocalParticipant, RemoteParticipant } from 'livekit-client';

import { RoomState } from '@/hooks';

import { LocalControls } from './LocalControls';
import { LocalParticipantView } from './LocalParticipantView';
import { RemoteParticipantControl } from './RemoteParticipantControl';
import { RemoteParticipantView } from './RemoteParticipantView';
import { RoomPageFallback } from './RoomPageFallback';

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  overflow: 'hidden',
});

export interface ParticipantsViewProps {
  roomState: RoomState;
}

export const ParticipantsView = ({ roomState }: ParticipantsViewProps) => {
  const { isConnecting, error, participants, room } = roomState;

  const localParticipant = participants.find((p) => p instanceof LocalParticipant);

  const remoteParticipant = participants.find((p) => p instanceof RemoteParticipant);

  if (error || isConnecting || !room || !localParticipant) {
    return <RoomPageFallback roomState={roomState} />;
  }

  return (
    <Container>
      <RemoteParticipantView participant={remoteParticipant}>
        <RemoteParticipantControl />
      </RemoteParticipantView>
      <LocalParticipantView participant={localParticipant}>
        <LocalControls hasLeaveButton roomState={roomState} />
      </LocalParticipantView>
    </Container>
  );
};
