import { useCallback, useState } from 'react';

import {
  AudioTrack,
  ConnectOptions,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  Track,
  connect,
} from 'livekit-client';

import { getAudioTracks } from '@/helpers';

interface HandlePause {
  pause(): void;
  resume(): void;
  isPaused: boolean;
}

export interface RoomState {
  connect: (url: string, token: string, options?: ConnectOptions) => Promise<Room | undefined>;
  isConnecting: boolean;
  room?: Room;
  /* all participants in the room, including the local participant. */
  participants: Participant[];
  /* all subscribed audio tracks in the room, not including local participant. */
  audioTracks: AudioTrack[];
  error?: Error;
  handlePause: HandlePause;
}

export const useRoom = (): RoomState => {
  const [room, setRoom] = useState<Room>();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [error, setError] = useState<Error>();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);

  const pauseFn = () => setIsPaused(true);
  const resumeFn = () => setIsPaused(false);

  const connectFn = useCallback(async (url: string, token: string, options?: ConnectOptions) => {
    setIsConnecting(true);
    try {
      const newRoom = await connect(url, token, options);
      setRoom(newRoom);

      const onTrackPublished = (publication: RemoteTrackPublication) => {
        publication.setSubscribed(true);
      };

      const onParticipantsChanged = () => {
        const remotes = Array.from(newRoom.participants.values());
        const participants: Participant[] = [newRoom.localParticipant];

        participants.push(...remotes);

        const participantsWithoutDuplicates = [...new Set([...participants])];

        setParticipants(Array.from(participantsWithoutDuplicates));
      };

      const handleAudioTracks = () => {
        newRoom.participants.forEach((participant: RemoteParticipant) => {
          participant.tracks.forEach((track) => {
            if (track.kind === 'audio') {
              track.setSubscribed(true);
            }
          });
        });

        const participants = Array.from(newRoom?.participants.values());
        const tracks = getAudioTracks(participants);
        setAudioTracks(tracks);
      };

      const onSubscribedTrackChanged = (track?: RemoteTrack) => {
        // ordering may have changed, re-sort
        onParticipantsChanged();
        if (track && track.kind !== Track.Kind.Audio) {
          return;
        }
        handleAudioTracks();
      };

      newRoom.once(RoomEvent.Disconnected, () => {
        setTimeout(() => setRoom(undefined));

        newRoom
          .off(RoomEvent.ParticipantConnected, onParticipantsChanged)
          .off(RoomEvent.ParticipantDisconnected, onParticipantsChanged)
          .off(RoomEvent.ActiveSpeakersChanged, onParticipantsChanged)
          .off(RoomEvent.TrackSubscribed, onSubscribedTrackChanged)
          .off(RoomEvent.TrackUnsubscribed, onSubscribedTrackChanged)
          .off(RoomEvent.LocalTrackPublished, onSubscribedTrackChanged)
          .off(RoomEvent.LocalTrackUnpublished, onSubscribedTrackChanged)
          .off(RoomEvent.AudioPlaybackStatusChanged, onParticipantsChanged)
          .off(RoomEvent.TrackPublished, onTrackPublished);
      });

      newRoom
        .on(RoomEvent.ParticipantConnected, onParticipantsChanged)
        .on(RoomEvent.ParticipantDisconnected, onParticipantsChanged)
        .on(RoomEvent.ActiveSpeakersChanged, onParticipantsChanged)
        .on(RoomEvent.TrackSubscribed, onSubscribedTrackChanged)
        .on(RoomEvent.TrackUnsubscribed, onSubscribedTrackChanged)
        .on(RoomEvent.LocalTrackPublished, onSubscribedTrackChanged)
        .on(RoomEvent.LocalTrackUnpublished, onSubscribedTrackChanged)
        // trigger a state change by re-sorting participants
        .on(RoomEvent.AudioPlaybackStatusChanged, onParticipantsChanged)
        .on(RoomEvent.TrackPublished, onTrackPublished);

      setIsConnecting(false);
      onSubscribedTrackChanged();

      return newRoom;
    } catch (error) {
      setIsConnecting(false);
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error('an error has occured'));
      }

      return undefined;
    }
  }, []);

  return {
    audioTracks,
    connect: connectFn,
    error,
    handlePause: { pause: pauseFn, resume: resumeFn, isPaused },
    isConnecting,
    participants,
    room,
  };
};
