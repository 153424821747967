import { PreJoinPage, RoomPage } from '@/pages';

import { useRoom } from './hooks';

export const Routes = () => {
  const roomState = useRoom();

  if (!roomState.room && !roomState.handlePause.isPaused && !roomState.isConnecting) {
    return <PreJoinPage roomState={roomState} />;
  }

  return <RoomPage roomState={roomState} />;
};
