import { ReactNode } from 'react';

import { Participant } from 'livekit-client';

import { useParticipant } from '@/hooks';

import { VideoCover } from '../VideoCover';
import { VideoRenderer } from '../VideoRenderer';

interface RemoteParticipantVideoProps {
  children?: ReactNode;
  participant: Participant;
}

export const RemoteParticipantVideo = ({ children, participant }: RemoteParticipantVideoProps) => {
  const { cameraPublication } = useParticipant(participant);

  return (
    <VideoRenderer sx={{ backgroundColor: 'grey.800' }} track={cameraPublication?.track}>
      <VideoCover isLoading={!cameraPublication?.track} videoEnabled />
      {children}
    </VideoRenderer>
  );
};
