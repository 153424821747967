import { useEffect, useState } from 'react';

import { Box, Typography, styled } from '@mui/material';
import { LocalVideoTrack, createLocalVideoTrack } from 'livekit-client';

import { useRoomContext } from '@/context';
import { aspectRatio, flexCenter } from '@/helpers';
import { useMedia } from '@/zustand';

import { AudioButton, VideoButton } from '../ControlButtons';
import { VideoCover } from '../VideoCover';
import { VideoRenderer } from '../VideoRenderer';

const ControlsWrapper = styled(Box)({
  ...flexCenter,
  position: 'absolute',
  bottom: 0,
  width: '100%',
  zIndex: 1,
  backgroundColor: 'transparent',
});

// TODO i18
export const PreJoinVideoView = () => {
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const { videoDevice } = useRoomContext();
  const { audioEnabled, setAudioEnabled, setVideoEnabled, videoEnabled } = useMedia(
    (state) => state
  );

  useEffect(() => {
    return () => {
      if (videoTrack) {
        videoTrack.stop();
      }
    };
  }, [videoTrack]);

  useEffect(() => {
    if (videoEnabled) {
      createLocalVideoTrack({
        deviceId: videoDevice?.deviceId,
      }).then((track) => {
        setVideoEnabled(true);
        setVideoTrack(track);
      });
    }
  }, [setVideoEnabled, videoDevice, videoEnabled]);

  const handleAudio = () => setAudioEnabled(!audioEnabled);

  const handleVideo = () => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoEnabled(false);
      setVideoTrack(undefined);
    } else {
      setVideoEnabled(true);
    }
  };

  const selectVideoDevice = () => {
    if (videoTrack) {
      if (videoTrack.mediaStreamTrack.getSettings().deviceId === videoDevice?.deviceId) {
        return;
      }
      videoTrack.stop();
    }
  };
  return (
    <VideoRenderer
      isLocal
      sx={{
        aspectRatio: aspectRatio.toString(),
        borderRadius: '1rem',
        left: '50%',
        maxHeight: '90%',
        transform: 'translateX(-50%)',
      }}
      track={videoTrack}
    >
      <VideoCover
        isLoading={!videoTrack && videoEnabled}
        sx={{ height: '100%' }}
        videoEnabled={videoEnabled}
      >
        <Typography align="center" variant="subtitle2">
          Deine Kamera ist deaktiviert.
        </Typography>
      </VideoCover>
      <ControlsWrapper sx={{ my: 3 }}>
        <AudioButton enabled={audioEnabled} onClick={handleAudio} />
        <VideoButton
          enabled={videoEnabled}
          onClick={handleVideo}
          onDeviceSelect={selectVideoDevice}
        />
      </ControlsWrapper>
    </VideoRenderer>
  );
};
