import { styled } from '@mui/material';

import { LoadingSpinner } from '@minimals/core';

import { Link } from '@/components';
import { flexCenter } from '@/helpers';
import { useRoomConnect } from '@/hooks';
import { RoomPageProps } from '@/pages';

export const Message = styled('div')({
  ...flexCenter,
});

// TODO i18n
export const RoomPageFallback = ({ roomState }: RoomPageProps) => {
  const { handlePause, isConnecting, error, room } = roomState;
  const { connect, loading } = useRoomConnect(roomState);

  const handleConnect = async () => {
    await connect();
    handlePause.resume();
  };

  if (error) {
    return <Message>Error {error.message}</Message>;
  }

  if (isConnecting || loading) {
    return <LoadingSpinner />;
  }

  if (!room) {
    return (
      <Message>
        Du pausierst.
        <Link inline onClick={handleConnect}>
          Klick hier
        </Link>
        um deine Pause zu beenden.
      </Message>
    );
  }

  return null;
};
