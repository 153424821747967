import { ReactNode, useEffect } from 'react';

import { Box, ClickAwayListener, IconButton, MenuItem, Popover, styled } from '@mui/material';

import { usePopver } from '@/hooks';
import { ChevronDownIcon } from '@/icons';

const Button = styled(IconButton)({
  position: 'absolute',
  top: '-0.8rem',
  right: 0,
  zIndex: 1,

  '&:hover': {
    backgroundColor: 'currentColor',
  },
});

interface DeviceSelectionProps {
  children: ReactNode;
  devices: MediaDeviceInfo[];
  onSelect?(device?: MediaDeviceInfo): void;
  selectedDevice: MediaDeviceInfo;
  setDevice(device: MediaDeviceInfo): void;
}

export const DeviceSelection = ({
  children,
  devices,
  onSelect,
  selectedDevice,
  setDevice,
}: DeviceSelectionProps) => {
  const { anchorEl, isOpen, handleOpen, handleClose } = usePopver();

  useEffect(() => {
    if (!selectedDevice) setDevice(devices[0]);
  }, [devices, selectedDevice]);

  const handleSelect = (device: MediaDeviceInfo) => {
    setDevice(device);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        position="relative"
        sx={{
          m: 1,
        }}
      >
        <Button
          onClick={handleOpen}
          size="large"
          sx={{
            backgroundColor: isOpen ? 'primary.main' : 'primary.light',
            p: 0.5,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            zIndex: 1100,
          }}
        >
          <ChevronDownIcon />
        </Button>
        {isOpen ? (
          <Popover
            PaperProps={{ sx: { p: 1.5 } }}
            elevation={14}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            {devices.map((device) => {
              const isActive = device.deviceId === selectedDevice?.deviceId;
              const activeStyles = isActive
                ? {
                    color: 'primary.main',
                    fontWeight: 700,
                  }
                : {};

              return (
                <MenuItem
                  sx={activeStyles}
                  onClick={() => {
                    handleSelect(device);
                    handleClose();
                    if (onSelect) onSelect(device);
                  }}
                  key={device.deviceId}
                >
                  {device.label}
                </MenuItem>
              );
            })}
          </Popover>
        ) : null}

        {children}
      </Box>
    </ClickAwayListener>
  );
};
