import { useState } from 'react';

import { Box, Button, ButtonGroup, Card, Divider, Typography, styled } from '@mui/material';

import { Fab } from '@minimals/core';

import { useQueueContext } from '@/context';
import {
  useAbortPatientMutation,
  usePatientLeavingMutation,
  usePatientToWaitingRoomMutation,
} from '@/graphql';
import { CallDeclineIcon, CheckIcon } from '@/icons';

const Container = styled(Card)({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  width: '80%',
  bottom: '5%',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
});

const Menu = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const fabSx = { m: 1 };

// TODO i18n
export const RemoteParticipantControl = () => {
  const { callId, callName, setCallId } = useQueueContext();
  const [showMenu, setShowMenu] = useState(false);
  // const [pause, setPause] = useState(false);
  const [patientLeaving] = usePatientLeavingMutation();
  const [abortPatient] = useAbortPatientMutation();
  // const [pauseCall, { error: pauseError }] = usePauseCallMutation();
  // const [resumeCall, { error: resumeError }] = useResumeCallMutation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLeaving = () => {
    patientLeaving({
      variables: { id: callId },
    });
    setCallId('');
  };

  const handleAbort = () => {
    abortPatient({
      variables: { id: callId },
    });
    setCallId('');
  };

  // const handlePause = async () => {
  //   if (!pause) {
  //     await pauseCall({ variables: { id: callId } });
  //     if (!pauseError) {
  //       setPause(true);
  //     }
  //   } else {
  //     await resumeCall({ variables: { id: callId } });
  //     if (!resumeError) {
  //       setPause(false);
  //     }
  //   }
  // };

  const justifyContent = showMenu ? 'center' : 'space-between';

  return (
    <Container sx={{ px: 2, py: 1, justifyContent }}>
      {showMenu ? (
        <Menu sx={{ py: 2 }}>
          <Typography align="center" variant="h6" sx={{ flexGrow: 1 }}>
            {callName} greeet abschließen
          </Typography>
          <WaitingRoomButtonGroup />
          <Divider />
          <Button onClick={handleLeaving} variant="contained">
            Patient verlässt die Praxis
          </Button>
          <Button onClick={toggleMenu} variant="outlined">
            Zurück zum Call
          </Button>
        </Menu>
      ) : (
        <>
          <Typography color="primary.main" variant="h6">
            {callName}
          </Typography>
          <Box>
            <Fab onClick={handleAbort} sx={fabSx}>
              <CallDeclineIcon />
            </Fab>
            {/* <Fab onClick={handlePause} sx={fabSx}>
              {pause ? 'play' : <PauseIcon />}
            </Fab> */}
            <Fab onClick={toggleMenu} sx={fabSx}>
              <CheckIcon />
            </Fab>
          </Box>
        </>
      )}
    </Container>
  );
};

const options = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
];

const WaitingRoomButtonGroup = () => {
  const { callId, setCallId } = useQueueContext();
  const [patientToWaitingRoom] = usePatientToWaitingRoomMutation();
  const [waitingTime, setWaitingTime] = useState<number>(10);

  const handleSelect = (e) => {
    setWaitingTime(Number(e.target.value));
  };

  const handleToWaitingRoom = () => {
    patientToWaitingRoom({
      variables: { id: callId, waitingTime },
    });
    setCallId('');
  };

  return (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <ButtonGroup>
        {options.map(({ value, label }) => (
          <Button
            key={value}
            onClick={handleSelect}
            value={value}
            variant={waitingTime === value ? 'contained' : 'outlined'}
          >
            {label}min
          </Button>
        ))}
      </ButtonGroup>
      <Button onClick={handleToWaitingRoom} fullWidth variant="contained">
        Ins Wartezimmer
      </Button>
    </Box>
  );
};
