import { ReactNode } from 'react';

import { Box, SxProps, styled } from '@mui/material';

import { LoadingSpinner } from '@minimals/core';

import { flexCenter } from '@/helpers';

const Cover = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  ...flexCenter,
  position: 'absolute',
  top: 0,
  flexDirection: 'column',
  padding: '1rem',
  maxWidth: '100%',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.grey[200],
  opacity: isVisible ? 1 : 0,
  transition: isVisible ? 'none' : 'opacity 0.5s',
  transitionDelay: '100ms',
  zIndex: 1,
}));

interface VideoCoverProps {
  children?: ReactNode;
  isLoading?: boolean;
  sx?: SxProps;
  videoEnabled: boolean;
}

// TODO i18n
export const VideoCover = ({ children, isLoading, sx, videoEnabled }: VideoCoverProps) => (
  <Cover sx={{ ...sx }} isVisible={!videoEnabled || isLoading}>
    {isLoading && <LoadingSpinner />}
    {!isLoading && !videoEnabled && !!children && <>{children}</>}
  </Cover>
);
