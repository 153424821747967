import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

import { parseJwt } from '@cubular/utils';

import { useRoomContext } from '@/context';
import { useRoomConnect } from '@/hooks';
import { PreJoinProps } from '@/pages';
import { useToken } from '@/zustand';

const getDaytime = (time: number) => {
  if (time > 3 && time < 12) return 'Morgen';
  if (time >= 12 && time < 18) return 'Tag';
  else return 'Abend';
};

// TODO i18n
export const PreJoinConnectView = ({ roomState }: PreJoinProps) => {
  const token = useToken((state) => state.token);
  const { isConnecting } = roomState;
  const { connect, loading } = useRoomConnect(roomState);
  const { serverUrl } = useRoomContext();

  const handleConnect = () => {
    if (
      window.location.protocol === 'https:' &&
      serverUrl.startsWith('ws://') &&
      !serverUrl.startsWith('ws://localhost')
    ) {
      alert('Unable to connect to insecure websocket from https');
      return;
    }

    connect();
  };

  const { family_name, given_name } = parseJwt(token);

  return (
    <Box>
      <Typography
        color="primary.main"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        variant="h3"
      >
        Guten {getDaytime(new Date().getHours())} {given_name} {family_name} ☕
      </Typography>

      <Typography sx={{ mb: 3 }} variant="h6">
        {format(new Date(), 'eeee dd MMM yyyy', {
          locale: de,
        })}
      </Typography>

      {/* <Typography variant="h5">Öffnungszeiten heute: 08:00- 18:30</Typography> */}
      {/* <Typography color="primary.main" sx={{ mb: 4 }} variant="body1">
        Digitaler Empfang an 3 Terminals | 1 weitere Empfangskraft online
      </Typography> */}

      <LoadingButton
        fullWidth
        loading={loading || isConnecting}
        onClick={handleConnect}
        size="large"
        variant="contained"
      >
        Empfang besetzen
      </LoadingButton>
    </Box>
  );
};
