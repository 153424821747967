import { useCallback, useEffect, useState } from 'react';

import { Room } from 'livekit-client';

export const useAudioDeviceSelection = () => {
  const [sources, setSources] = useState<MediaDeviceInfo[]>([]);

  const listAudioDevices = useCallback(async () => {
    const devices = await Room.getLocalDevices('audioinput');
    setSources(devices);
  }, []);

  useEffect(() => {
    listAudioDevices();
    navigator.mediaDevices.addEventListener('devicechange', listAudioDevices);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listAudioDevices);
    };
  }, []);

  return sources;
};

export const useVideoDeviceSelection = () => {
  const [sources, setSources] = useState<MediaDeviceInfo[]>([]);

  const listVideoDevices = useCallback(async () => {
    const devices = await Room.getLocalDevices('videoinput');
    setSources(devices);
  }, []);

  useEffect(() => {
    listVideoDevices();
    navigator.mediaDevices.addEventListener('devicechange', listVideoDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', listVideoDevices);
    };
  }, []);

  return sources;
};
