import { StrictMode } from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import { LoadingSpinner } from '@minimals/core';
import { ThemeProvider } from '@minimals/theme';
import { SnackbarProvider } from '@minimals/use-snackbar';

import keycloak from './Keycloak';
import { Routes } from './Routes';
import { RoomContextProvider } from './context';
import { ApolloProviderComponent } from './graphql/ApolloProviderComponent';
import { AuthGuard } from './guards/AuthGuard';
import { PreJoinLayout } from './layouts';
import { palette } from './theme';
import { useToken } from './zustand';

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  sslRequired: 'external',
  resource: 'operator-app',
  publicClient: true,
  confidentialPort: 0,
};

const customThemes = {
  palette: { ...palette },
  typography: {
    fontFamily: 'Outfit, sans-serif',
  },
};

export const App = () => {
  const setToken = useToken((state) => state.setToken);

  return (
    <ThemeProvider customThemes={customThemes}>
      <ReactKeycloakProvider
        authClient={keycloak}
        isLoadingCheck={(keycloak) => !keycloak?.token}
        LoadingComponent={
          <PreJoinLayout>
            <LoadingSpinner />
          </PreJoinLayout>
        }
        initOptions={keycloakProviderInitConfig}
        onTokens={({ token }) => {
          setToken(token);
        }}
      >
        <StrictMode>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            disableWindowBlurListener
          >
            <RoomContextProvider>
              <AuthGuard>
                <ApolloProviderComponent>
                  <Routes />
                </ApolloProviderComponent>
              </AuthGuard>
            </RoomContextProvider>
          </SnackbarProvider>
        </StrictMode>
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
};
