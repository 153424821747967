import { Fab } from '@minimals/core';

import { useRoomContext } from '@/context';
import { useAudioDeviceSelection, useVideoDeviceSelection } from '@/hooks';
import { MicIcon, MutedMicIcon, VideoIcon, VideoMutedIcon } from '@/icons';

import { DeviceSelection } from './DeviceSelection';

type ButtonProps = {
  enabled: boolean;
  onClick(): void;
  onDeviceSelect?(device?: MediaDeviceInfo): void;
};

export const AudioButton = ({ enabled, onClick, onDeviceSelect }: ButtonProps) => {
  const { audioDevice, setAudioDevice } = useRoomContext();
  const devices = useAudioDeviceSelection();

  return (
    <DeviceSelection
      devices={devices}
      selectedDevice={audioDevice}
      setDevice={setAudioDevice}
      onSelect={onDeviceSelect}
    >
      <Fab onClick={onClick}>{enabled ? <MicIcon /> : <MutedMicIcon />}</Fab>
    </DeviceSelection>
  );
};

export const VideoButton = ({ enabled, onClick, onDeviceSelect }: ButtonProps) => {
  const { videoDevice, setVideoDevice } = useRoomContext();
  const devices = useVideoDeviceSelection();

  return (
    <DeviceSelection
      devices={devices}
      selectedDevice={videoDevice}
      setDevice={setVideoDevice}
      onSelect={onDeviceSelect}
    >
      <Fab onClick={onClick}>{enabled ? <VideoIcon /> : <VideoMutedIcon />}</Fab>
    </DeviceSelection>
  );
};
