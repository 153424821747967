import { useTheme } from '@mui/material';

import { SvgComponentProps } from './svg-components.types';

export const StandbyIcon = ({ size = 24, fill }: SvgComponentProps) => {
  const { palette } = useTheme();
  const color = fill || palette.primary.main;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0333 12.3871C13.0333 13.2435 12.3481 13.9355 11.5 13.9355C10.6519 13.9355 9.96667 13.2435 9.96667 12.3871V1.54839C9.96667 0.693387 10.6519 0 11.5 0C12.3481 0 13.0333 0.693387 13.0333 1.54839V12.3871ZM3.06667 12.3871C3.06667 17.0903 6.8425 20.9032 11.5 20.9032C16.1575 20.9032 19.9333 17.0903 19.9333 12.3871C19.9333 9.75484 18.7498 7.39839 16.8906 5.83548C16.239 5.28871 16.1479 4.31274 16.6894 3.65516C17.2308 2.99758 18.1988 2.90806 18.8504 3.45484C21.3804 5.58387 23 8.79677 23 12.3435C23 18.7984 17.849 24 11.5 24C5.15104 24 0 18.7984 0 12.3435C0 8.79677 1.61719 5.58387 4.14958 3.45484C4.80125 2.90806 5.76917 2.99758 6.31063 3.65516C6.80896 4.31274 6.76104 5.28871 6.10938 5.83548C4.25021 7.39839 3.06667 9.75484 3.06667 12.3871Z"
        fill={color}
      />
    </svg>
  );
};
