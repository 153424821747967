import { Box, BoxProps, keyframes, styled } from '@mui/material';

import { flexCenter } from '@/helpers';
import { GreeetsAvatar } from '@/icons';

const animation = keyframes`
  0% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.1);
  }

   100% {
    transform: scale(1);
  }
`;

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'animate',
})<{ animate: boolean }>(({ animate }) => ({
  ...flexCenter,
  backgroundColor: 'rgba(208,242,255, 0.6)',
  width: '6rem',
  height: '6rem',
  borderRadius: '10rem',
  animation: animate ? `${animation} 1s infinite ease` : 'none',
}));

interface AvatarLogoProps {
  animate?: boolean;
}

export const AvatarLogo = ({ animate, ...boxProps }: AvatarLogoProps & BoxProps) => (
  <Container animate={animate} {...boxProps}>
    <GreeetsAvatar />
  </Container>
);
