import { ReactElement } from 'react';

import { Box, SxProps } from '@mui/material';

interface LogoProps {
  children: ReactElement;
  sx?: SxProps;
}

export const Logo = ({ children, sx }: LogoProps) => (
  <Box sx={{ height: 20, display: 'inline-block', ...sx }}>{children}</Box>
);
