import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Timestamp: any;
};

export type ActiveInformation = {
  __typename?: 'ActiveInformation';
  activeOperators: Scalars['Int'];
  activeTerminals: Scalars['Int'];
};

export type CallRequest = {
  __typename?: 'CallRequest';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationInformation?: Maybe<ContactInformation>;
  locationName?: Maybe<Scalars['String']>;
  status: CallStatus;
  terminalName?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Timestamp']>;
};

export enum CallResult {
  Aborted = 'ABORTED',
  Leave = 'LEAVE',
  ToWaitingroom = 'TO_WAITINGROOM',
}

export type CallResultInput = {
  result: CallResult;
  waittime?: InputMaybe<Scalars['Int']>;
};

export enum CallStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type ContactInformation = {
  __typename?: 'ContactInformation';
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ContactInformationInput = {
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  closingTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationInformation?: Maybe<ContactInformation>;
  name: Scalars['String'];
  openingTime?: Maybe<Scalars['String']>;
  operators?: Maybe<Array<Maybe<User>>>;
};

export type LocationInput = {
  closingTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  locationInformation?: InputMaybe<ContactInformationInput>;
  name?: InputMaybe<Scalars['String']>;
  openingTime?: InputMaybe<Scalars['String']>;
  operators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCall?: Maybe<Scalars['String']>;
  createLocation?: Maybe<Location>;
  createOrUpdateContactInformation?: Maybe<ContactInformation>;
  createOrganization?: Maybe<Organization>;
  createUser?: Maybe<User>;
  deleteLocation?: Maybe<Scalars['String']>;
  deleteOrganization?: Maybe<Scalars['String']>;
  finishCall?: Maybe<Scalars['String']>;
  orderTerminal?: Maybe<Scalars['String']>;
  pauseCall?: Maybe<Scalars['String']>;
  resumeCall?: Maybe<Scalars['String']>;
  updateLocation?: Maybe<Location>;
  updateOrganization?: Maybe<Organization>;
  updateTerminal?: Maybe<Terminal>;
  updateUser?: Maybe<User>;
};

export type MutationAcceptCallArgs = {
  callRequest: Scalars['ID'];
};

export type MutationCreateLocationArgs = {
  input?: InputMaybe<LocationInput>;
};

export type MutationCreateOrUpdateContactInformationArgs = {
  input?: InputMaybe<ContactInformationInput>;
};

export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<OrganizationInput>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<UserInput>;
};

export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};

export type MutationFinishCallArgs = {
  id: Scalars['ID'];
  result: CallResultInput;
};

export type MutationPauseCallArgs = {
  id: Scalars['ID'];
};

export type MutationResumeCallArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<LocationInput>;
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<OrganizationInput>;
};

export type MutationUpdateTerminalArgs = {
  input?: InputMaybe<TerminalInput>;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserInput>;
};

export type Organization = {
  __typename?: 'Organization';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationInformation?: Maybe<ContactInformation>;
};

export type OrganizationInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  newFileName?: InputMaybe<Scalars['String']>;
  organizationInformation?: InputMaybe<ContactInformationInput>;
};

export type Query = {
  __typename?: 'Query';
  getLocation?: Maybe<Location>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getOrganization?: Maybe<Organization>;
  getQueue?: Maybe<Array<Maybe<CallRequest>>>;
  getRoomToken?: Maybe<RoomToken>;
  getTerminal?: Maybe<Terminal>;
  getTerminalsByLocations?: Maybe<Array<Maybe<Terminal>>>;
  getTerminalsByOrganization?: Maybe<Array<Maybe<Terminal>>>;
  getUser?: Maybe<User>;
  getUsers?: Maybe<Array<Maybe<User>>>;
  me?: Maybe<User>;
};

export type QueryGetLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTerminalArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTerminalsByLocationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueueMessage = {
  __typename?: 'QueueMessage';
  callRequest: CallRequest;
  type: Scalars['String'];
};

export enum QueueMessageType {
  New = 'NEW',
  Resolved = 'RESOLVED',
}

export type RoomToken = {
  __typename?: 'RoomToken';
  expiresAt?: Maybe<Scalars['Timestamp']>;
  room?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeCall?: Maybe<Scalars['String']>;
  queue?: Maybe<Array<Maybe<CallRequest>>>;
};

export type Terminal = {
  __typename?: 'Terminal';
  description?: Maybe<Scalars['String']>;
  error?: Maybe<TerminalError>;
  id: Scalars['ID'];
  information?: Maybe<TerminalInformation>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TerminalError = {
  __typename?: 'TerminalError';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type TerminalInformation = {
  __typename?: 'TerminalInformation';
  createdAt?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type TerminalInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  role?: Maybe<UserRole>;
};

export type UserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  job?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  newFileName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  LocationAdmin = 'LOCATION_ADMIN',
  Operator = 'OPERATOR',
}

export type QueueFragment = {
  __typename?: 'CallRequest';
  id: string;
  status: CallStatus;
  terminalName?: string | null;
  locationName?: string | null;
  description?: string | null;
  timeout?: any | null;
  locationInformation?: {
    __typename?: 'ContactInformation';
    city?: string | null;
    street?: string | null;
  } | null;
};

export type AcceptCallMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AcceptCallMutation = { __typename?: 'Mutation'; acceptCall?: string | null };

export type AbortPatientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AbortPatientMutation = { __typename?: 'Mutation'; finishCall?: string | null };

export type PatientLeavingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PatientLeavingMutation = { __typename?: 'Mutation'; finishCall?: string | null };

export type PatientToWaitingRoomMutationVariables = Exact<{
  id: Scalars['ID'];
  waitingTime: Scalars['Int'];
}>;

export type PatientToWaitingRoomMutation = { __typename?: 'Mutation'; finishCall?: string | null };

export type PauseCallMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PauseCallMutation = { __typename?: 'Mutation'; pauseCall?: string | null };

export type ResumeCallMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResumeCallMutation = { __typename?: 'Mutation'; resumeCall?: string | null };

export type GetRoomTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoomTokenQuery = {
  __typename?: 'Query';
  getRoomToken?: {
    __typename?: 'RoomToken';
    room?: string | null;
    value: string;
    expiresAt?: any | null;
  } | null;
};

export type GetTerminalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTerminalsQuery = {
  __typename?: 'Query';
  getTerminalsByLocations?: Array<{
    __typename?: 'Terminal';
    id: string;
    location?: {
      __typename?: 'Location';
      closingTime?: string | null;
      openingTime?: string | null;
    } | null;
  } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: { __typename?: 'User'; name?: string | null } | null;
};

export type QueueSubscriptionVariables = Exact<{ [key: string]: never }>;

export type QueueSubscription = {
  __typename?: 'Subscription';
  queue?: Array<{
    __typename?: 'CallRequest';
    id: string;
    status: CallStatus;
    terminalName?: string | null;
    locationName?: string | null;
    description?: string | null;
    timeout?: any | null;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      street?: string | null;
    } | null;
  } | null> | null;
};

export const QueueFragmentDoc = gql`
  fragment Queue on CallRequest {
    id
    status
    terminalName
    locationName
    description
    timeout
    locationInformation {
      city
      street
    }
  }
`;
export const AcceptCallDocument = gql`
  mutation AcceptCall($id: ID!) {
    acceptCall(callRequest: $id)
  }
`;
export type AcceptCallMutationFn = Apollo.MutationFunction<
  AcceptCallMutation,
  AcceptCallMutationVariables
>;

/**
 * __useAcceptCallMutation__
 *
 * To run a mutation, you first call `useAcceptCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCallMutation, { data, loading, error }] = useAcceptCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptCallMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptCallMutation, AcceptCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptCallMutation, AcceptCallMutationVariables>(
    AcceptCallDocument,
    options
  );
}
export type AcceptCallMutationHookResult = ReturnType<typeof useAcceptCallMutation>;
export type AcceptCallMutationResult = Apollo.MutationResult<AcceptCallMutation>;
export type AcceptCallMutationOptions = Apollo.BaseMutationOptions<
  AcceptCallMutation,
  AcceptCallMutationVariables
>;
export const AbortPatientDocument = gql`
  mutation AbortPatient($id: ID!) {
    finishCall(id: $id, result: { result: ABORTED })
  }
`;
export type AbortPatientMutationFn = Apollo.MutationFunction<
  AbortPatientMutation,
  AbortPatientMutationVariables
>;

/**
 * __useAbortPatientMutation__
 *
 * To run a mutation, you first call `useAbortPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortPatientMutation, { data, loading, error }] = useAbortPatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAbortPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<AbortPatientMutation, AbortPatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AbortPatientMutation, AbortPatientMutationVariables>(
    AbortPatientDocument,
    options
  );
}
export type AbortPatientMutationHookResult = ReturnType<typeof useAbortPatientMutation>;
export type AbortPatientMutationResult = Apollo.MutationResult<AbortPatientMutation>;
export type AbortPatientMutationOptions = Apollo.BaseMutationOptions<
  AbortPatientMutation,
  AbortPatientMutationVariables
>;
export const PatientLeavingDocument = gql`
  mutation PatientLeaving($id: ID!) {
    finishCall(id: $id, result: { result: LEAVE })
  }
`;
export type PatientLeavingMutationFn = Apollo.MutationFunction<
  PatientLeavingMutation,
  PatientLeavingMutationVariables
>;

/**
 * __usePatientLeavingMutation__
 *
 * To run a mutation, you first call `usePatientLeavingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientLeavingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientLeavingMutation, { data, loading, error }] = usePatientLeavingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientLeavingMutation(
  baseOptions?: Apollo.MutationHookOptions<PatientLeavingMutation, PatientLeavingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatientLeavingMutation, PatientLeavingMutationVariables>(
    PatientLeavingDocument,
    options
  );
}
export type PatientLeavingMutationHookResult = ReturnType<typeof usePatientLeavingMutation>;
export type PatientLeavingMutationResult = Apollo.MutationResult<PatientLeavingMutation>;
export type PatientLeavingMutationOptions = Apollo.BaseMutationOptions<
  PatientLeavingMutation,
  PatientLeavingMutationVariables
>;
export const PatientToWaitingRoomDocument = gql`
  mutation PatientToWaitingRoom($id: ID!, $waitingTime: Int!) {
    finishCall(id: $id, result: { result: TO_WAITINGROOM, waittime: $waitingTime })
  }
`;
export type PatientToWaitingRoomMutationFn = Apollo.MutationFunction<
  PatientToWaitingRoomMutation,
  PatientToWaitingRoomMutationVariables
>;

/**
 * __usePatientToWaitingRoomMutation__
 *
 * To run a mutation, you first call `usePatientToWaitingRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientToWaitingRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientToWaitingRoomMutation, { data, loading, error }] = usePatientToWaitingRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      waitingTime: // value for 'waitingTime'
 *   },
 * });
 */
export function usePatientToWaitingRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatientToWaitingRoomMutation,
    PatientToWaitingRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatientToWaitingRoomMutation, PatientToWaitingRoomMutationVariables>(
    PatientToWaitingRoomDocument,
    options
  );
}
export type PatientToWaitingRoomMutationHookResult = ReturnType<
  typeof usePatientToWaitingRoomMutation
>;
export type PatientToWaitingRoomMutationResult =
  Apollo.MutationResult<PatientToWaitingRoomMutation>;
export type PatientToWaitingRoomMutationOptions = Apollo.BaseMutationOptions<
  PatientToWaitingRoomMutation,
  PatientToWaitingRoomMutationVariables
>;
export const PauseCallDocument = gql`
  mutation PauseCall($id: ID!) {
    pauseCall(id: $id)
  }
`;
export type PauseCallMutationFn = Apollo.MutationFunction<
  PauseCallMutation,
  PauseCallMutationVariables
>;

/**
 * __usePauseCallMutation__
 *
 * To run a mutation, you first call `usePauseCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseCallMutation, { data, loading, error }] = usePauseCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseCallMutation(
  baseOptions?: Apollo.MutationHookOptions<PauseCallMutation, PauseCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PauseCallMutation, PauseCallMutationVariables>(
    PauseCallDocument,
    options
  );
}
export type PauseCallMutationHookResult = ReturnType<typeof usePauseCallMutation>;
export type PauseCallMutationResult = Apollo.MutationResult<PauseCallMutation>;
export type PauseCallMutationOptions = Apollo.BaseMutationOptions<
  PauseCallMutation,
  PauseCallMutationVariables
>;
export const ResumeCallDocument = gql`
  mutation ResumeCall($id: ID!) {
    resumeCall(id: $id)
  }
`;
export type ResumeCallMutationFn = Apollo.MutationFunction<
  ResumeCallMutation,
  ResumeCallMutationVariables
>;

/**
 * __useResumeCallMutation__
 *
 * To run a mutation, you first call `useResumeCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeCallMutation, { data, loading, error }] = useResumeCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeCallMutation(
  baseOptions?: Apollo.MutationHookOptions<ResumeCallMutation, ResumeCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResumeCallMutation, ResumeCallMutationVariables>(
    ResumeCallDocument,
    options
  );
}
export type ResumeCallMutationHookResult = ReturnType<typeof useResumeCallMutation>;
export type ResumeCallMutationResult = Apollo.MutationResult<ResumeCallMutation>;
export type ResumeCallMutationOptions = Apollo.BaseMutationOptions<
  ResumeCallMutation,
  ResumeCallMutationVariables
>;
export const GetRoomTokenDocument = gql`
  query GetRoomToken {
    getRoomToken {
      room
      value
      expiresAt
    }
  }
`;

/**
 * __useGetRoomTokenQuery__
 *
 * To run a query within a React component, call `useGetRoomTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoomTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoomTokenQuery, GetRoomTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomTokenQuery, GetRoomTokenQueryVariables>(
    GetRoomTokenDocument,
    options
  );
}
export function useGetRoomTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoomTokenQuery, GetRoomTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoomTokenQuery, GetRoomTokenQueryVariables>(
    GetRoomTokenDocument,
    options
  );
}
export type GetRoomTokenQueryHookResult = ReturnType<typeof useGetRoomTokenQuery>;
export type GetRoomTokenLazyQueryHookResult = ReturnType<typeof useGetRoomTokenLazyQuery>;
export type GetRoomTokenQueryResult = Apollo.QueryResult<
  GetRoomTokenQuery,
  GetRoomTokenQueryVariables
>;
export const GetTerminalsDocument = gql`
  query GetTerminals {
    getTerminalsByLocations {
      id
      location {
        closingTime
        openingTime
      }
    }
  }
`;

/**
 * __useGetTerminalsQuery__
 *
 * To run a query within a React component, call `useGetTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTerminalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(
    GetTerminalsDocument,
    options
  );
}
export function useGetTerminalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(
    GetTerminalsDocument,
    options
  );
}
export type GetTerminalsQueryHookResult = ReturnType<typeof useGetTerminalsQuery>;
export type GetTerminalsLazyQueryHookResult = ReturnType<typeof useGetTerminalsLazyQuery>;
export type GetTerminalsQueryResult = Apollo.QueryResult<
  GetTerminalsQuery,
  GetTerminalsQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      name
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const QueueDocument = gql`
  subscription Queue {
    queue {
      ...Queue
    }
  }
  ${QueueFragmentDoc}
`;

/**
 * __useQueueSubscription__
 *
 * To run a query within a React component, call `useQueueSubscription` and pass it any options that fit your needs.
 * When your component renders, `useQueueSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueSubscription({
 *   variables: {
 *   },
 * });
 */
export function useQueueSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<QueueSubscription, QueueSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<QueueSubscription, QueueSubscriptionVariables>(
    QueueDocument,
    options
  );
}
export type QueueSubscriptionHookResult = ReturnType<typeof useQueueSubscription>;
export type QueueSubscriptionResult = Apollo.SubscriptionResult<QueueSubscription>;
