import { useTheme } from '@mui/material';

export const CallAcceptIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="34"
      height="34"
      style={{ transform: 'rotate(225deg)' }}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11043 13.7472C12.1291 7.74418 21.8707 7.74418 27.8893 13.7472C29.6857 15.5436 29.6857 18.4562 27.8893 20.2526C27.708 20.4367 27.5091 20.6025 27.2953 20.7476C27.0962 20.8954 26.8831 21.0233 26.659 21.1294C26.3397 21.2828 25.9632 21.2559 25.669 21.0587L20.4576 17.7848C20.1706 17.6039 19.9949 17.2897 19.9909 16.9504C19.9839 16.7595 19.9839 16.7454 20.4011 15.4231C18.1766 14.5916 15.7275 14.5866 13.4997 15.4089C13.9169 16.7454 13.9169 16.7595 13.9098 16.9504C13.9059 17.2897 13.7302 17.6039 13.4431 17.7848L8.23175 21.0587C7.93899 21.2412 7.57285 21.2599 7.26301 21.1082C7.0355 21.0001 6.81783 20.8723 6.61248 20.7264C6.4063 20.5771 6.21241 20.4116 6.03265 20.2314C4.27167 18.4161 4.30642 15.5197 6.11043 13.7472Z"
        fill={theme.palette.success.main || '#54D62C'}
      />
    </svg>
  );
};

export const CallDeclineIcon = () => {
  const theme = useTheme();
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.11043 13.7472C12.1291 7.74418 21.8707 7.74418 27.8893 13.7472C29.6857 15.5436 29.6857 18.4562 27.8893 20.2526C27.708 20.4367 27.5091 20.6025 27.2953 20.7476C27.0962 20.8954 26.8831 21.0233 26.659 21.1294C26.3397 21.2828 25.9632 21.2559 25.669 21.0587L20.4576 17.7848C20.1706 17.6039 19.9949 17.2897 19.9909 16.9504C19.9839 16.7595 19.9839 16.7454 20.4011 15.4231C18.1766 14.5916 15.7275 14.5866 13.4997 15.4089C13.9169 16.7454 13.9169 16.7595 13.9098 16.9504C13.9059 17.2897 13.7302 17.6039 13.4431 17.7848L8.23175 21.0587C7.93899 21.2412 7.57285 21.2599 7.26301 21.1082C7.0355 21.0001 6.81783 20.8723 6.61248 20.7264C6.4063 20.5771 6.21241 20.4116 6.03265 20.2314C4.27167 18.4161 4.30642 15.5197 6.11043 13.7472Z"
        fill={theme.palette.error.main || '#AA2932'}
      />
    </svg>
  );
};
