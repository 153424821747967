import { ReactNode, useCallback } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { useTextSnackbar } from '@minimals/use-snackbar';

import { useToken } from '@/zustand';

interface ApolloProviderProps {
  children: ReactNode;
}

export const ApolloProviderComponent = ({ children }: ApolloProviderProps) => {
  const token = useToken((state) => state.token);
  const snackbar = useTextSnackbar();

  const createApolloClient = useCallback((token: string) => {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));

    const httpLink = new HttpLink({
      uri: import.meta.env.VITE_GREEETS_GQL_QUERY_URL,
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        on: {
          connected: () => snackbar('success', 'Subscription connected.'),
          closed: () => snackbar('error', 'Subscription closed.'),
        },
        url: import.meta.env.VITE_GREEETS_GQL_SUBSCRIPTON_URL,
        connectionParams: {
          Authorization: token,
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      authLink.concat(httpLink)
    );

    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
    });
  }, []);

  const client = createApolloClient(token);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
