import { ReactElement, useState } from 'react';

import { Box, Snackbar, styled } from '@mui/material';

import { Fab } from '@minimals/core';
import { LeaveIcon } from '@minimals/icons';

import { flexCenter } from '@/helpers';
import { RoomState, useParticipant } from '@/hooks';
import { StandbyIcon } from '@/icons';
import { useMedia } from '@/zustand';

import { AudioButton, VideoButton } from '../ControlButtons';

const ControlsWrapper = styled(Box)({
  ...flexCenter,
  width: '100%',
  zIndex: 1,
  height: '100px',
});

export interface ControlsProps {
  hasAudioButton?: boolean;
  hasVideoButton?: boolean;
  hasLeaveButton?: boolean;
  hasStandyButton?: boolean;
  roomState: RoomState;
}

// TODO i18n
export const LocalControls = ({
  hasAudioButton = true,
  hasVideoButton = true,
  hasStandyButton,
  hasLeaveButton,
  roomState,
}: ControlsProps) => {
  const { room, handlePause } = roomState;
  const { cameraPublication } = useParticipant(room.localParticipant);
  const setVideoEnabled = useMedia((state) => state.setVideoEnabled);

  const [hasError, setError] = useState(null);

  let muteButton: ReactElement | undefined;
  if (hasAudioButton) {
    const enabled = room.localParticipant.isMicrophoneEnabled;
    muteButton = (
      <AudioButton
        enabled={enabled}
        onClick={() => room.localParticipant.setMicrophoneEnabled(!enabled)}
        onDeviceSelect={(device) => room.switchActiveDevice('audioinput', device.deviceId)}
      />
    );
  }

  let videoButton: ReactElement | undefined;
  if (hasVideoButton) {
    const enabled = !(cameraPublication?.isMuted ?? true);
    videoButton = (
      <VideoButton
        enabled={enabled}
        onClick={() => {
          setVideoEnabled(!enabled);
          room.localParticipant.setCameraEnabled(!enabled);
        }}
        onDeviceSelect={(device) => room.switchActiveDevice('videoinput', device.deviceId)}
      />
    );
  }

  let standyButton: ReactElement | undefined;
  if (hasStandyButton) {
    standyButton = (
      <Fab
        onClick={() => {
          handlePause.pause();
          room.disconnect();
        }}
        sx={{ m: 1 }}
      >
        <StandbyIcon />
      </Fab>
    );
  }

  let leaveButton: ReactElement | undefined;
  if (hasLeaveButton) {
    leaveButton = (
      <Fab
        onClick={() => {
          room.disconnect();
        }}
        sx={{ m: 1 }}
      >
        <LeaveIcon />
      </Fab>
    );
  }

  return (
    <ControlsWrapper>
      {muteButton}
      {videoButton}
      {standyButton}
      {leaveButton}
      <Snackbar
        open={!!hasError}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        message="Permission denied. Please enabled screen sharing in your browser and system settings."
      />
    </ControlsWrapper>
  );
};
