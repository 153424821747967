import { ReactNode } from 'react';

import { Card, styled } from '@mui/material';

import { Logo } from '@/components';
import { GreeetsIcon } from '@/icons';

const Container = styled('div')({
  height: '100vh',
  maxHeight: '100vh',
  display: 'grid',
  gridTemplateRows: 'auto 8fr',
  gridTemplateColumns: 'auto 4fr',
});

const TopBar = styled(Card)({
  gridColumn: '1 / span 2',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
  margin: '0.5rem 0.5rem 0',
  height: '80px',
  zIndex: 10,
});

interface RoomLayoutProps {
  children: ReactNode;
}

export const RoomLayout = ({ children }: RoomLayoutProps) => (
  <Container>
    <TopBar>
      <Logo>
        <GreeetsIcon />
      </Logo>
    </TopBar>
    {children}
  </Container>
);
