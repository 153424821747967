import { Box, styled } from '@mui/material';

import { Logo, PreJoinConnectView, PreJoinVideoView } from '@/components';
import { RoomState } from '@/hooks';
import { GreeetsIcon } from '@/icons';
import { PreJoinLayout } from '@/layouts';

export interface PreJoinProps {
  roomState: RoomState;
}

const Grid = styled(Box)({
  alignItems: 'center',
  display: 'grid',
  gap: '80px',
  gridTemplateColumns: 'auto minmax(280px, 50%)',
  height: '100%',
  width: '100%',
});

export const PreJoinPage = ({ roomState }: PreJoinProps) => {
  return (
    <PreJoinLayout>
      <Logo sx={{ mb: 5 }}>
        <GreeetsIcon />
      </Logo>

      <Grid>
        <PreJoinVideoView />
        <PreJoinConnectView roomState={roomState} />
      </Grid>
    </PreJoinLayout>
  );
};
