import { ReactNode } from 'react';

import { styled } from '@mui/material';

const StyledLink = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

interface LinkProps {
  children: ReactNode;
  inline?: boolean;
  onClick(): void;
}

export const Link = ({ children, inline, onClick }: LinkProps) => {
  const Component = <StyledLink onClick={onClick}>{children}</StyledLink>;

  if (inline) {
    return (
      <>
        &nbsp;
        {Component}
        &nbsp;
      </>
    );
  }

  return Component;
};
