import { ReactNode, createContext, useContext, useState } from 'react';

interface RoomContextType {
  audioDevice: MediaDeviceInfo;
  serverUrl: string;
  setAudioDevice(device: MediaDeviceInfo): void;
  setVideoDevice(device: MediaDeviceInfo): void;
  videoDevice: MediaDeviceInfo;
}

interface RoomContextProps {
  children: ReactNode;
}

const serverUrl = import.meta.env.VITE_GREEETS_LIVEKIT_URL;

const defaultContext: RoomContextType = {
  audioDevice: null,
  serverUrl,
  setAudioDevice: () => ({}),
  setVideoDevice: () => ({}),
  videoDevice: null,
};

export const RoomContext = createContext(defaultContext);

export const RoomContextProvider = ({ children }: RoomContextProps) => {
  const [audioDevice, setAudioDevice] = useState(null);
  const [videoDevice, setVideoDevice] = useState(null);

  return (
    <RoomContext.Provider
      value={{
        audioDevice,
        serverUrl,
        setAudioDevice,
        setVideoDevice,
        videoDevice,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};

export const useRoomContext = () => useContext(RoomContext);
