import { Backdrop, Button, Card, Typography } from '@mui/material';
import { Room } from 'livekit-client';

import { flexCenter } from '@/helpers';
import { MutedMicIcon } from '@/icons';

interface EnableAudioModalProps {
  room?: Room;
}

// TODO i18n
export const EnableAudioModal = ({ room }: EnableAudioModalProps) => (
  <Backdrop sx={{ zIndex: 1 }} open={room?.canPlaybackAudio === false}>
    <Card sx={{ p: 6, ...flexCenter, flexDirection: 'column' }}>
      <Typography sx={{ pb: 3 }} variant="h4">
        Audio ist deaktiviert
      </Typography>
      <Button
        color="inherit"
        size="large"
        variant="contained"
        startIcon={<MutedMicIcon />}
        onClick={() => {
          room.startAudio();
        }}
      >
        Klick zum aktivieren
      </Button>
    </Card>
  </Backdrop>
);
