import { AudioRenderer, Queue } from '@/components';
import { QueueContextProvider } from '@/context';
import { RoomState } from '@/hooks';
import { RoomLayout } from '@/layouts';

import { EnableAudioModal, ParticipantsView } from '@/components/room';

export interface RoomPageProps {
  roomState: RoomState;
}

// TODO i18n
export const RoomPage = ({ roomState }: RoomPageProps) => {
  const { room } = roomState;

  return (
    <RoomLayout>
      <QueueContextProvider>
        <Queue />
        <ParticipantsView roomState={roomState} />
      </QueueContextProvider>

      <EnableAudioModal room={room} />
      {roomState.audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}
    </RoomLayout>
  );
};
