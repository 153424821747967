import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { ConnectOptions, VideoPresets } from 'livekit-client';

import { useTextSnackbar } from '@minimals/use-snackbar';

import { useRoomContext } from '@/context';
import { GetRoomTokenQuery, useGetRoomTokenLazyQuery } from '@/graphql';
import { onRoomConnected } from '@/helpers';
import { useMedia } from '@/zustand';

import { RoomState } from './useRoom';

const connectOptions: ConnectOptions = {
  adaptiveStream: true,
  dynacast: true,
  videoCaptureDefaults: {
    resolution: VideoPresets.hd.resolution,
  },
  publishDefaults: {
    videoEncoding: VideoPresets.hd.encoding,
    simulcast: true,
  },
  logLevel: 'debug',
};

interface UseRoomConnect {
  error?: ApolloError;
  loading?: boolean;
  connect(): Promise<void>;
}

// TODO i18n
export const useRoomConnect = (roomState: RoomState): UseRoomConnect => {
  const snackbar = useTextSnackbar();
  const [error, setError] = useState(null);
  const audioEnabled = useMedia((state) => state.audioEnabled);
  const videoEnabled = useMedia((state) => state.videoEnabled);
  const { audioDevice, serverUrl, videoDevice } = useRoomContext();

  const onCompleted = async (data: GetRoomTokenQuery) => {
    if (error) {
      snackbar('error', 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.');
      return;
    }
    const token = data.getRoomToken.value;

    await roomState.connect(serverUrl, token, connectOptions).then((room) => {
      if (!room) {
        setError(true);
        return;
      }

      onRoomConnected({
        audioDevice,
        audioEnabled,
        room,
        videoDevice,
        videoEnabled,
      });

      return () => {
        room.disconnect();
      };
    });
  };

  const [getRoomToken, { loading }] = useGetRoomTokenLazyQuery({
    onCompleted,
    onError: () => setError(true),
  });

  const connect = async () => {
    await getRoomToken();
  };

  return { connect, error, loading };
};
